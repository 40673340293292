import { memo } from "react";

import { ReactComponent as Location } from "assets/images/location.svg";
import { IMember } from "models";
import { GetImg } from "utils";
import styles from "./styles.module.scss";

const MemberCard = ({
	member,
	onClick,
	selected = false,
}: {
	member: IMember;
	onClick: () => void;
	selected?: boolean;
}) => {
	return (
		<section
			className={
				selected
					? styles.memberSelectedCardContainer
					: styles.memberCardContainer
			}
			onClick={onClick}
		>
			<div className={styles.memberDetails}>
				<GetImg className={styles.image} endpoint={member.picture} />
				<div className={styles.details}>
					<h3 className={styles.memberName}>{member.full_name}</h3>
					<p className={styles.memberType}>
						{member.membership_type}
					</p>
					{member?.industry && (
						<p className={styles.memberType}>{member.industry}</p>
					)}
					{member?.profession && (
						<p className={styles.memberType}>{member.profession}</p>
					)}
					<span className={styles.location}>
						<Location /> {member.distance}
					</span>
				</div>
			</div>
		</section>
	);
};
export default memo(MemberCard);
